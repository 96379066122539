


.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: rgb(82, 154, 206);
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .button2 {
    background-color: transparent; 
    color: black; 
    border: 2px solid #082934;
  }
  
  .button2:hover {
    background-color: #0c3864;
    color: rgb(255, 255, 255);
  }

  body {
    font-family: "Ubuntu";
  }