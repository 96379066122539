.button-container {
    right: 20px; /* Default right position for desktop */
  
    /* Media query for screens smaller than 768px (typical mobile screens) */
    @media (max-width: 3080px) {
      position: fixed;
      bottom: 60px;
      right: 50%; /* Center horizontally */
      transform: translateX(50%); /* Center horizontally */
    }
  }